import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PostCardMinimal from '../../../components/post-card-minimal/post-card-minimal';
import Pagination from '../../../components/pagination/pagination';
import BlogPostsWrapper, { SecTitle } from './style';

type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {
  const data = useStaticQuery(graphql`
      query {
        allSitePage(filter: { path: { eq: "/page/1" } }) {
          nodes {
            context {
              numPages
              currentPage
            }
          }
        }
        posts: allStrapiArticle(filter:{featured: {eq: false}}) {
          edges {
            node {
              id
              title
              slug
              published_date
              description
              coverImage {
                childImageSharp {
                  fluid(maxWidth: 325, maxHeight: 325, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              article_tags {
                tag_name
              }
            }
          }
        }
      }  
  `);
  const posts = data.posts.edges;
  const totalPage = data.allSitePage.nodes[0].context.numPages;
  const currentPage = data.allSitePage.nodes[0].context.currentPage;

  return (
    <BlogPostsWrapper>
      <SecTitle>Latest Stories</SecTitle>
      {posts.map(({ node }: any) => {
        const title = node.title;
        const tags = node.article_tags.map((article_tag: {tag_name: string}) => article_tag.tag_name);

        return (
          <PostCardMinimal
            key={node.id}
            title={title}
            image={node.coverImage !== null ? node.coverImage.childImageSharp.fluid : null}
            url={`/${node.slug}`}
            description={node.description}
            date={node.published_date}
            tags={tags}
          />
        );
      })}

      {totalPage >> 1 ? (
        <Pagination
          nextLink="/page/2"
          currentPage={currentPage}
          totalPage={totalPage}
        />
      ) : (
        ''
      )}
    </BlogPostsWrapper>
  );
};

export default Posts;
