import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../../components/social-profile/social-profile';
import {
  IntroWrapper,
  IntroImage,
  IntroTitle,
  Desciption,
  IntroInfo,
} from './style';
import {
  IoLogoFacebook,
  IoLogoInstagram,
} from 'react-icons/io';

type IntroProps = {};

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/irinachristinaa',
    tooltip: 'Facebook',
  },
  {
    icon: <IoLogoInstagram />,
    url:  'https://www.instagram.com/irinachristina/',
    tooltip: 'Instagram',
  },
];

const Intro: React.FunctionComponent<IntroProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/author_1.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 210, maxHeight: 210, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          author
        }
      }
      strapiContent(type: {eq: "home-welcome-content"}){
        value
      }
    }
  `);

  const { site: { siteMetadata: {author}} , strapiContent } = Data;
  const AuthorImage = Data.avatar.childImageSharp.fluid;
  const homeWelcomeContent = (strapiContent) ? strapiContent.value : '';

  return (
    <IntroWrapper>
      <IntroImage>
        <Image fluid={AuthorImage} alt="author" />
      </IntroImage>
      <IntroInfo>
        <IntroTitle>
          Hey! Ik ben <b>{author}</b>
        </IntroTitle>
        <Desciption>{homeWelcomeContent}</Desciption>
        <SocialProfile items={SocialLinks} />
      </IntroInfo>
    </IntroWrapper>
  );
};

export default Intro;
