import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FeaturedCard from '../../../components/featured-card/featured-card';
import {
  FeaturedPostWrapper,
  FeaturedPostRow,
  FeaturedPostCol,
  SecTitle,
} from './style';

type FeaturedPostsProps = {};

const FeaturedPosts: React.FunctionComponent<FeaturedPostsProps> = () => {
  const Data = useStaticQuery(graphql`
  query {
    featuredPosts: allStrapiArticle(filter:{featured: {eq: true}}) {
      edges {
        node {
          id
          title
          slug
          published_date
          description
          coverImage {
            childImageSharp {
              fluid(maxWidth: 325, maxHeight: 325, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          article_tags {
            tag_name
          }
        }
      }
    }
  } 
  `);
  
  const {featuredPosts: { edges }} = Data;

  return (
    <FeaturedPostWrapper>
      <SecTitle>Featured Stories</SecTitle>
      <FeaturedPostRow>
        {edges.map(({ node }: any) => {
          const title = node.title || node.fields.slug;
          const tags = node.article_tags.map((article_tag: {tag_name: string}) => article_tag.tag_name);

          return (
            <FeaturedPostCol key={title}>
              <FeaturedCard
                title={title}
                image={
                  node.coverImage == null
                    ? null
                    : node.coverImage.childImageSharp.fluid 
                }
                url={`/${node.slug}`}
                tags={tags}
                description={node.description}
                overlay={true}
              />
            </FeaturedPostCol>
          );
        })}
      </FeaturedPostRow>
    </FeaturedPostWrapper>
  );
};

export default FeaturedPosts;
