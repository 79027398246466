import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PersonalBlog from '../containers/home';
import SEO from '../components/seo';

const HomePage = (props: any) => {
  const { data: {site: {siteMetadata: {description, title}}} } = props;

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={[
         'Juridische blog',
         'Rechten',
         'Rechten blog',
         'Juridische vragen makkelijk beantwoord',
        ]}
      />
      <PersonalBlog />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query fetchSeoTitleAndDescription {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
